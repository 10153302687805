import { Box, CircularProgress } from "@mui/material";
import React from "react";
import logo from "../resources/svg/logoSmall.svg";

const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <img src={logo} alt="logo" style={{ width: "50px" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          position: "absolute",
        }}
      >
        <CircularProgress size={100} thickness={1.5} />
      </Box>
    </Box>
  );
};

export default Loader;
