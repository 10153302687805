import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import Design1 from "../resources/images/Design1.jpg";
import Design2 from "../resources/images/Design2.jpg";
import Design3 from "../resources/images/Design3.jpg";
import Design4 from "../resources/images/Design4.jpg";

const Story = () => {
  const designs = [
    { image: Design1, title: "Architecture" },
    { image: Design2, title: "Custom Furniture Design" },
    { image: Design3, title: "Interior Design" },
    { image: Design4, title: "Landscape Design" },
  ];

  return (
    <Container
      maxWidth="xl"
      sx={{ pl: "0 !important", pr: "0 !important", my: 8 }}
    >
      <Box
        // data-aos="fade-up"
        sx={{
          px: {
            lg: 12,
            md: 8,
            sm: 4,
            xs: 2,
          },
        }}
      >
        <Box textAlign="center" my={2}>
          <Typography variant="h2" fontWeight="900">
            Luxury Architectural Interior Studio, Building Dreams to Reality
          </Typography>
          <Typography variant="body1" my={2}>
            In order to design for a consistent and engaging experience it is
            not enough to implement a bunch of ideas. These ideas need a
            cohesive <br />
            vision that makes sense. A vision that brings a consistent concept
            to life. That narrates a story!
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {designs.map((design, index) => (
            <Grid
              key={index}
              item
              xs={6}
              md={3}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    p: {
                      md: 2,
                      xs: 0,
                    },
                  }}
                >
                  <img
                    src={design.image}
                    alt={`Design ${index + 1}`}
                    width="100%"
                    height="100%"
                    style={{ background: "#E6E6E6" }}
                    loading="lazy"
                  />
                </Box>
                <Typography variant="h6" mt={1} textAlign="center">
                  {design.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Story;
