import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Footer from "../common/Footer";
import Navbar from "../layout/Navbar";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <>
      <Navbar />
      <Container
        maxWidth="xl"
        sx={{ textAlign: "left", pl: "0 !important", pr: "0 !important" }}
      >
        <Box
          sx={{
            px: { md: 6, sm: 4, xs: 2 },
            py: {
              md: 4,
              xs: 2,
            },
          }}
        >
          <Typography variant="h1" color="primary.main" sx={{ mb: 5 }}>
            Terms & Conditions
          </Typography>

          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Boketto Designs or Boketto Designs LLP, is committed to protecting
            the privacy and security of your personal information. This Terms
            and Conditions describe how we collect, use, and disclose personal
            information when you visit our website
            <Link
              style={{
                color: "#212B36",
              }}
              to="http://www.bokettodesigns.in"
            >
              {" "}
              http://www.bokettodesigns.in
            </Link>
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            This terms of use agreement ("Terms") constitutes a legally binding
            contract between Boketto Designs LLP hereinafter referred to as
            Boketto Designs for all purposes, and our affiliates, and you, with
            respect to your use of all properties (Web, mobile or otherwise)
            owned or operated by Boketto Designs (collectively, the
            "Properties"), and all products and services available from Boketto
            Designs, vendors, or other third parties in connection with the
            Properties (individually and collectively with the Properties, the
            "Services").
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Acceptance of Terms
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            By accessing and using this website, you acknowledge that you have
            read, understood, and agree to be bound by these terms and
            conditions. If you do not agree to the Terms, please do not use our
            Services.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Use of the Site
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            <ul
              style={{
                paddingLeft: "40px",
              }}
            >
              <li>You agree to use the site for lawful purposes only.</li>
              <li>
                You will not engage in any conduct that could harm the website
                or interfere with its normal functioning.
              </li>
            </ul>
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            User Accounts
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            <ul
              style={{
                paddingLeft: "40px",
              }}
            >
              <li>
                If the website requires users to create accounts, users are
                responsible for maintaining the confidentiality of their account
                information and password.
              </li>
              <li>
                You will not engage in any conduct that could harm the website
                or interfere with its normal functioning.
              </li>
            </ul>
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Intellectual Property
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            All content on this website, including text, graphics, logos,
            images, and software, is the property of Mistri and is protected by
            intellectual property laws.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Limitation of Liability
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We are not liable for any direct, indirect, incidental, special, or
            consequential damages that result from your use of or inability to
            use the site.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            External Links
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            The website may contain links to third-party websites. We are not
            responsible for the content or practices of these sites.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Modification of Terms
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We reserve the right to modify these terms and conditions at any
            time. The updated terms will be effective upon posting to the
            website.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Termination
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We reserve the right to terminate or suspend your access to the
            website for any reason, without notice.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Governing Law
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            These terms and conditions are governed by the laws of the
            Government of India.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Contact Information
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            For questions or concerns regarding these terms and conditions,
            please contact us at{" "}
            <Link
              style={{
                color: "#212B36",
              }}
              to="mailto:info@bokettodesigns.in"
            >
              info@bokettodesigns.in
            </Link>
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
