import React, { useRef } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../layout/Navbar";
import InteriorBanner from "../components/InteriorBanner";
import Footer from "../common/Footer";
import Story from "../components/Story";
import Design from "../components/Design";
import AboutUs from "../components/AboutUs";
import OurProcess from "../components/OurProcess";
import ImgCompare from "../components/ImgCompare";
import Testimonials from "../components/Testimonials";

const Home = () => {
  const footerRef = useRef(null);
  return (
    <>
      <Helmet>Interior</Helmet>
      <Navbar footerRef={footerRef} />
      <InteriorBanner />
      <Story />
      <Design />
      <AboutUs />
      <OurProcess />
      <ImgCompare />
      <Testimonials />
      <Footer ref={footerRef}/>
    </>
  );
};

export default Home;
