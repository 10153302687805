import React from "react";
import { Container, Box, Typography, IconButton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { LuInstagram, LuMail } from "react-icons/lu";
import { FaLinkedinIn } from "react-icons/fa";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

const TestimonialSlide = ({ text, author, position }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    textAlign="center"
    maxWidth="800px"
  >
    <Typography variant="body1" my={2}>
      {text}
    </Typography>
    <Typography variant="h6" fontWeight="700">
      {author}
    </Typography>
    <Typography variant="body1">{position}</Typography>
  </Box>
);

const SocialMediaIcons = () => (
  <Box display="flex" alignItems="center" gap={2.5}>
    <a
      href="https://www.instagram.com/bokettodesigns/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconButton
        sx={{
          color: "#FFF",
          backgroundColor: "#AE4034",
          "&:hover": {
            backgroundColor: "#343434",
          },
        }}
      >
        <LuInstagram size={18} />
      </IconButton>
    </a>
    <a
      href="https://in.linkedin.com/company/boketto-designs"
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconButton
        sx={{
          color: "#FFF",
          backgroundColor: "#AE4034",
          "&:hover": {
            backgroundColor: "#343434",
          },
        }}
      >
        <FaLinkedinIn size={18} />
      </IconButton>
    </a>
    <a href="mailto:info@bokettodesigns.in">
      <IconButton
        sx={{
          color: "#FFF",
          backgroundColor: "#AE4034",
          "&:hover": {
            backgroundColor: "#343434",
          },
        }}
      >
        <LuMail size={18} />
      </IconButton>
    </a>
  </Box>
);

const Testimonials = () => {
  // useEffect(() => {
  //   AOS.init();
  // }, []);

  return (
    <Container
      maxWidth="xl"
      id="testimonials"
      sx={{ pl: "0 !important", pr: "0 !important", my: 10 }}
    >
      <Typography variant="h1" textAlign="center" fontWeight="800">
        Testimonials
      </Typography>
      <Box sx={{ margin: "auto", width: { md: "50%", sm: "80%", xs: "100%" } }}>
        <Swiper
          spaceBetween={30}
          // autoHeight={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
        >
          <SwiperSlide>
            <TestimonialSlide
              text='"Working with Sakshi Gupta was one of the best teams I have worked with. She was very easy to approach, understood everything, and paid close attention to delivering her finest work. With the help of her creative and hardworking team, I wish her the best of luck for all her upcoming ventures and hope to work with her team in many of our upcoming projects."'
              author="Chef Lalit Tuli"
              position="Managing Director, Tandoori Nights"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSlide
              text='"Working with Sakshi and the Boketto Team was an absolute pleasure! Their keen eye for detail transformed our restaurant into a stunning space.They took my vague ideas and turned them into something straight out of a design"'
              author="Akash Arora"
              position="Restaurant Owner"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSlide
              text='"Year 2020 … Sakshi Shaifali .. two young girls had just started their independent venture- ‘Boketto’when Covidstruck. But these resilient girls marched on regardless!I got glowing feedback about them from my friend and Idecided to go ahead with them.The Boketto team did an amazing job at my place and made my house a ‘home’! Thorough,sincere, aesthetic and particular about deadlines! That’s Team Boketto!!"'
              author="Anupama Mehtani"
              position="Client"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSlide
              text='"We are so grateful to have chosen Sakshi and her team, Boketto Designs, for the renovation of our house. Sakshi and her team combined my ideas and vision with their expertise and experience to create a beautifully designed space. They considered not only what the space was going to look like but also how it was going to be used. I am extremely happy with the services provided by Sakshi and her team at Boketto Designs and with the way our space turned out."'
              author="Alok Mehta"
              position="Proprietor, NMC Engineers"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSlide
              text="I was so impressed with Boketto Designs work that I had to recommend them to everyone I know. They were not only incredibly professional and hardworking, but they also had a real knack for understanding my style and creating a space that I never could have imagined. For example, I couldn't find the right spot for my TV in lobby - the layout just seemed awkward. But my amazing interior designer came up with the genius idea of mounting it under the stairs! It freed up so much space and actually made the lobby feel even bigger. Their choice of fabrics, colors, and finishes was spot-on, and they helped me find unique pieces that I absolutely love. Best part is that they always available to answer my questions and address my concern."
              author="Ajay Kumar Gupta (Director)"
              position="Aryan Enterprises Pt. Ltd"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSlide
              text="Dear Shefali and Sakshi, we are extremely happy to share our thoughts with you both and your team at Boketto designs for giving an absolutely majestic look to our Lawyers chamber in the precincts of Delhi High court at New Delhi. You and your team has left no stone unturned in giving the chamber a new look and making it more pleasant place to be in and working with more zeal . It’s always a difficult task to make a small place more work efficient, clean and substantially maximise its utility. Undoubtedly you both have done a magician’s job and the chamber has been given a rich look to say the least. We all thank you both and your team again and extend our warm wishes to you all. May you all achieve lots of glory and success in your work and future projects ."
              author="Manish Vashisht"
              position="Senior Advocate, High Court Delhi"
            />
          </SwiperSlide>
        </Swiper>
      </Box>

      <Box
        sx={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 5,
          paddingX: 2,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
            color: "#975030",
            mb: 2,
          }}
        >
          Looking to elevate the <br />
          aesthetic of your space ?
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center", fontWeight: 400 }}>
          Have a question for us? Let's talk. <br /> For all project inquires,
          please email <br />{" "}
          <Link
            style={{
              color: "#212B36",
            }}
            to="mailto:info@bokettodesigns.in"
          >
            info@bokettodesigns.in
          </Link>{" "}
          with the subject NEW PROJECT
        </Typography>
        {/* <SocialMediaIcons /> */}
      </Box>
    </Container>
  );
};

export default Testimonials;
