import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FaInstagram, FaUserClock, FaLinkedinIn } from "react-icons/fa";
import { LoadingButton } from "@mui/lab";
import { IoCall, IoMail } from "react-icons/io5";
import "aos/dist/aos.css";
import { useFormik } from "formik";
import * as yup from "yup";
import BLogo from "../resources/images/Bottom_Logo.png";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { LuChevronDown } from "react-icons/lu";
import countryCodesJson from "../common/json/country.json";

const validationSchema = yup.object({
  name: yup.string().trim().required("* Name is required"),
  phone: yup
    .string("* Please enter valid number")
    .length(10, "* Please enter valid number")
    .matches(/^[0-9]+$/, "* Please enter valid number")
    .typeError("* Please enter valid number")
    .required("* Phone is Required"),
  message: yup.string().trim().required("* Message is required"),
  subject: yup.string().trim().required("* Subject is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("* Email is required"),
});

const Footer = () => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState();

  const handleFormModalOpen = () => {
    setFormModalOpen(true);
  };
  const handleFormModalClose = () => {
    setFormModalOpen(false);
  };

  const featuresLinks = [
    { href: "/", text: "Home" },
    { href: "/privacy-policy", text: "Privacy & Policy" },
    { href: "/terms-and-conditions", text: "Terms & Conditions" },
    // { href: "/disclaimer", text: "Disclaimer" },
  ];
  const addressLinks = [
    {
      href: "tel:+919315927692",
      text: "+91 9315927692",
      icon: <IoCall size={25} />,
    },
    {
      href: "tel:+919090298383",
      text: "+91 9090298383",
    },
    {
      text: "Monday - Saturday 10am- 7:30 pm",
      icon: <FaUserClock size={25} />,
    },
    {
      href: "mailto:info@bokettodesigns.in",
      text: "info@bokettodesigns.in",
      icon: <IoMail size={25} />,
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      countryCode: countryCodesJson[97],
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      const config = {
        headers: {
          "Api-Key": process.env.REACT_APP_API_KEY,
        },
      };

      const body = {
        ...values,
        isFromBoketto: true,
        name: values.name,
        email: values.email,
        countryCode: values.countryCode.code,
        phone: values.phone,
        subject: values.subject,
        message: values.message,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/contact`, body, config)
        .then((response) => {
          enqueueSnackbar(response?.data?.message, { variant: "success" });
          setIsLoading(false);
          handleFormModalClose();
          formik.resetForm();
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <Box
        id="contact"
        sx={{
          paddingTop: { xs: 6 },
          paddingBottom: { md: 5 },
          backgroundColor: "#282923",
          color: "#FFF",
          paddingX: { lg: 12, md: 8, sm: 4, xs: 2 },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ pl: "0 !important", pr: "0 !important" }}
        >
          <Grid container spacing={6} sx={{ marginBottom: "60px" }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={2}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: {
                      xs: 1,
                      sm: 3,
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xs: "150px",
                        sm: "180px",
                        md: "220px",
                      },
                    }}
                  >
                    <img src={BLogo} alt="mb-logo" width="100%" height="100%" />
                  </Box>
                  {/* <Typography
                  sx={{
                    fontWeight: "600",
                    marginBottom: "10px",
                    color: "#975030",
                    mb: 0,
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "20px",
                    },
                  }}
                  variant="h3"
                >
                  BOKETTO DESIGNS
                </Typography> */}
                </Box>
              </Box>
              <Typography variant="h6" color="white" mt>
                Located in Delhi NCR and Chandigarh Tricity, we are a leading interior
                design firm dedicated to transforming diverse spaces into
                stunning environments. Contact us today to elevate your
                surroundings with our expert design solutions.
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
                <Link
                  href="https://www.instagram.com/bokettodesigns/"
                  passHref
                  target="_blank"
                >
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#975030",
                      borderRadius: "50%",
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    <FaInstagram size={22} />
                  </Box>
                </Link>
                <Link
                  href="https://in.linkedin.com/company/boketto-designs"
                  passHref
                  target="_blank"
                >
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#975030",
                      borderRadius: "50%",
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    <FaLinkedinIn size={22} />
                  </Box>
                </Link>

                <Link href="mailto:info@bokettodesigns.in" passHref>
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#975030",
                      borderRadius: "50%",
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    <IoMail size={22} />
                  </Box>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                sx={{
                  fontWeight: "600",
                  marginBottom: "10px",
                  color: "#975030",
                }}
                variant="h3"
              >
                Features
              </Typography>
              <Box sx={{ mt: 3 }}>
                {featuresLinks.map((link, index) => (
                  <Link
                    key={index}
                    href={link.href}
                    passHref
                    target={link.href === "/" ? "_self" : "_blank"}
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginBottom: "10px",
                        color: "white",
                        pl: 3,
                      }}
                      variant="h6"
                    >
                      {link.text}
                    </Typography>
                  </Link>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Typography
                sx={{
                  fontWeight: "600",
                  marginBottom: "10px",
                  color: "#975030",
                  mb: 3,
                }}
                variant="h3"
              >
                Get In Touch
              </Typography>
              <Button
                sx={{
                  background: "white",
                  height: 50,
                  ml: 3,
                  display: "flex",
                  gap: 2,
                  color: "#975030",
                  "&:hover": {
                    background: "white",
                  },
                }}
                onClick={handleFormModalOpen}
              >
                <IoCall size={20} />
                Schedule Consultation
              </Button>
              <Box sx={{ mt: 3 }}>
                {addressLinks.map((link, index) => (
                  <Link
                    key={index}
                    href={link.href}
                    passHref
                    sx={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      pl: 3,
                    }}
                  >
                    {link.icon && (
                      <Box sx={{ width: "30px", color: "white" }}>
                        {link.icon}
                      </Box>
                    )}
                    <Typography
                      sx={{
                        fontWeight: "400",
                        ml: link.icon ? 0 : "45px",
                        marginBottom: "10px",
                        color: "white",
                        display: "flex",
                        gap: "10px",
                      }}
                      variant="h6"
                    >
                      {link.text}
                    </Typography>
                  </Link>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column-reverse" },
              justifyContent: "center",
              ml: {
                lg: 5,
                xs: 0,
              },
              mr: {
                lg: 10,
                xs: 0,
              },
            }}
          >
            <Box>
              <Typography
                variant="h6"
                textAlign={"center"}
                sx={{
                  fontWeight: 400,
                  my: {
                    md: 0,
                    xs: 4,
                  },
                }}
              >
                &copy; {new Date().getFullYear()} Boketto Designs. All rights
                reserved.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Modal
        open={formModalOpen}
        onClose={handleFormModalClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 300,
              sm: 500,
              md: 600,
            },
            backgroundColor: "white",
            borderRadius: 3,
            boxShadow: 24,
            p: 3,
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              mb: 3,
            }}
          >
            <Typography variant="h2">Schedule Consultation</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="text"
                  name="name"
                  placeholder="Name *"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="email"
                  name="email"
                  placeholder="Email *"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 1,
                mb: 2,
                position: "relative",
              }}
            >
              <Fragment>
                <Select
                  labelId="countryCode"
                  id="countryCode"
                  label="countryCode"
                  variant="standard"
                  name="countryCode"
                  IconComponent={(props) => (
                    <LuChevronDown size={22} color="#AE4034" {...props} />
                  )}
                  renderValue={(selected) => selected.dial_code}
                  onChange={formik.handleChange}
                  value={formik.values.countryCode}
                  sx={{
                    position: "absolute",
                    width: "80px !important",
                    "&.MuiInput-root": {
                      border: "none !important",
                      zIndex: 9,
                      fontWeight: 500,
                      color: "#000",
                      height: "100%",
                      "#countryCode": {
                        textAlign: "center !important",
                      },
                    },
                  }}
                  disableUnderline
                >
                  {countryCodesJson.map((countryCode, index) => (
                    <MenuItem
                      key={index}
                      value={countryCode}
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography variant="subtitle2">
                        {countryCode.dial_code}
                      </Typography>
                      <Typography variant="subtitle2">
                        ({countryCode.name})
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "realtive",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    sx={{
                      height: "50%",
                      borderColor: "#AE4034",
                      borderWidth: "1px",
                      position: "absolute",
                      zIndex: 9,
                      marginLeft: "180px",
                    }}
                  />
                </Box>
              </Fragment>
              <TextField
                sx={{
                  ".MuiInputBase-input": {
                    paddingLeft: "100px !important",
                  },
                }}
                fullWidth
                type="text"
                placeholder="Phone Number"
                id="phone"
                name="phone"
                variant="outlined"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
            </FormControl>
            {formik.touched.phone && Boolean(formik.errors.phone) && (
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "red", ml: 1.5, mt: 0.5, mb: 2 }}
              >
                {formik.touched.phone && formik.errors.phone}
              </Typography>
            )}
            <Box mb={2}>
              <TextField
                fullWidth
                type="text"
                name="subject"
                placeholder="Subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              />
            </Box>
            <Box mb={2}>
              <TextField
                multiline
                rows={4}
                fullWidth
                type="text"
                name="message"
                placeholder="Message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#975030" }} />
                }
                sx={{ my: 2, px: 8 }}
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default Footer;
