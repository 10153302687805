import {
  Box,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import Logo from "../resources/images/logo.png";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { IoMenu } from "react-icons/io5";

const navHomeItems = [
  { name: "Home", href: "#boketto" },
  { name: "About Us", href: "#aboutUs" },
  { name: "Past Projects", href: "#pastProjects" },
  { name: "Our Process", href: "#ourProcess" },
  { name: "Testimonials", href: "#testimonials" },
  { name: "Contact", href: "#contact" },
];

const Navbar = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 280,
        background: "white",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <img src={Logo} alt="illustrator" style={{ height: "50px" }} />
      </Box>
      <Box
        sx={{
          mb: 3,
          display: {
            xs: "flex",
            sm: "none",
          },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ display: "flex", gap: 0, cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <Typography variant="h6" color="primary" fontWeight={800}>
            BOKETTO DESIGNS
          </Typography>
        </Box>
      </Box>
      <List
        sx={{
          backgroundColor: "white !important",
          color: "#000 !important",
        }}
      >
        {navHomeItems.map((item, index) => (
          <HashLink
            to={item.href}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                sx={{
                  paddingX: "20px",
                  borderBottom:
                    index !== navHomeItems.length - 1
                      ? "1px solid rgba(145, 158, 171, 0.24)"
                      : "none",
                }}
                component="a"
                href={item.link}
              >
                <ListItemText
                  primary={item.name}
                  sx={{
                    ".MuiTypography-root": {
                      fontWeight: 600,
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </HashLink>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Box>
        <Box
          sx={{
            background: "#F3ECE6",
            backdropFilter: "blur(10px)",
          }}
        >
          <Container
            maxWidth="xl"
            sx={{ pl: "0 !important", pr: "0 !important" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingX: { xs: 2 },
                paddingY: 3,
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: {
                    xs: 1,
                    sm: 3,
                  },
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    width: { xs: "50px", sm: "60px", md: "80px" },
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  gap: 3,
                }}
              >
                <HashLink
                  to="#boketto"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      marginBottom: "10px",
                      color: "#975030",
                      mb: 0,
                      fontSize: {
                        xs: "16px",
                      },
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                    variant="h3"
                  >
                    Home
                  </Typography>
                </HashLink>
                <HashLink
                  to="#aboutUs"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      marginBottom: "10px",
                      color: "#975030",
                      mb: 0,
                      fontSize: {
                        xs: "16px",
                      },
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                    variant="h3"
                  >
                    About Us
                  </Typography>
                </HashLink>
                <HashLink
                  to="#pastProjects"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      marginBottom: "10px",
                      color: "#975030",
                      mb: 0,
                      fontSize: {
                        xs: "16px",
                      },
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                    variant="h3"
                  >
                    Past Projects
                  </Typography>
                </HashLink>
              </Box>
              <Typography
                sx={{
                  fontWeight: "800",
                  marginBottom: "10px",
                  color: "#975030",
                  mb: 0,
                  fontSize: {
                    xs: "16px",
                    sm: "20px",
                    md: "24px",
                    lg: "28px",
                  },
                  // display: {
                  //   xs: "none",
                  //   sm: "block",
                  // },
                }}
                variant="logo"
              >
                BOKETTO DESIGNS
              </Typography>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  gap: 3,
                }}
              >
                <HashLink
                  to="#ourProcess"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      marginBottom: "10px",
                      color: "#975030",
                      mb: 0,
                      fontSize: {
                        xs: "16px",
                      },
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                    variant="h3"
                  >
                    Our Process
                  </Typography>
                </HashLink>
                <HashLink
                  to="#testimonials"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      marginBottom: "10px",
                      color: "#975030",
                      mb: 0,
                      fontSize: {
                        xs: "16px",
                      },
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                    variant="h3"
                  >
                    Testimonials
                  </Typography>
                </HashLink>
                <HashLink
                  to="#contact"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      marginBottom: "10px",
                      color: "#975030",
                      mb: 0,
                      fontSize: {
                        xs: "16px",
                      },
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                    variant="h3"
                  >
                    Contact
                  </Typography>
                </HashLink>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    md: "none",
                    lg: "flex",
                  },
                  opacity: {
                    xs: 1,
                    md: 0,
                  },
                  justifyContent: "end",
                  width: { xs: "50px", sm: "60px", md: "80px" },
                }}
              >
                <Button
                  onClick={toggleDrawer("right", true)}
                  sx={{
                    minWidth: "40px",
                    minHeight: "40px",
                    height: "40px",
                    padding: 0,
                  }}
                >
                  <IoMenu size={32} />
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </>
  );
};

export default Navbar;
