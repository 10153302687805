import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { Link } from "react-router-dom";
import Navbar from "../layout/Navbar";
import Footer from "../common/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Container
        maxWidth="xl"
        sx={{ textAlign: "left", pl: "0 !important", pr: "0 !important" }}
      >
        <Box
          sx={{
            px: { md: 6, sm: 4, xs: 2 },
            py: {
              md: 4,
              xs: 2,
            },
          }}
        >
          <Typography variant="h1" color="primary.main" sx={{ mb: 5 }}>
            Privacy Policy
          </Typography>

          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Boketto Designs(“us”, “we”, or “our”) operates the{" "}
            <Link>bokettodesigns.in </Link> website (the “Service”).
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            This page informs you of our policies regarding the collection, use
            and disclosure of Personal Information when you use our Service.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We will not use or share your information with anyone except as
            described in this Privacy Policy.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We use your Personal Information for providing and improving the
            Service. By using the Service, you agree to the collection and use
            of information in accordance with this policy. Unless otherwise
            defined in this Privacy Policy, terms used in this Privacy Policy
            have the same meanings as in our Terms and Conditions, accessible at
            bokettodesigns.in
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Information Collection And Use
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include, but
            is not limited to, your email address, name, phone number, postal
            address, other information (“Personal Information”).
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We collect this information for the purpose of providing the
            Service, identifying and communicating with you, responding to your
            requests/inquiries, servicing your purchase orders, and improving
            our services.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Log Data
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We may also collect information that your browser sends whenever you
            visit our Service (“Log Data”). This Log Data may include
            information such as your computer’s Internet Protocol (“IP”)
            address, browser type, browser version, the pages of our Service
            that you visit, the time and date of your visit, the time spent on
            those pages and other statistics.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            In addition, we may use third party services such as Google
            Analytics that collect, monitor and analyze this type of information
            in order to increase our Service’s functionality. These third party
            service providers have their own privacy policies addressing how
            they use such information.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Cookies
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Cookies are files with a small amount of data, which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            web site and transferred to your device. We use cookies to collect
            information in order to improve our services for you.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. The Help feature on most browsers
            provide information on how to accept cookies, disable cookies or to
            notify you when receiving a new cookie.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            If you do not accept cookies, you may not be able to use some
            features of our Service and we recommend that you leave them turned
            on.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            DoubleClick Cookie
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Google, as a third party vendor, uses cookies to serve ads on our
            Service. Google’s use of the DoubleClick cookie enables it and its
            partners to serve ads to our users based on their visit to our
            Service or other web sites on the Internet.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            You may opt out of the use of the DoubleClick Cookie for
            interest-based advertising by visiting the Google Ads Settings web
            page.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Behavioral Remarketing
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            The OutsideIn Consultants uses remarketing services to advertise on
            third party web sites to you after you visited our Service. We, and
            our third party vendors, use cookies to inform, optimize and serve
            ads based on your past visits to our Service.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Google AdWords remarketing service is provided by Google Inc.You can
            opt-out of Google Analytics for Display Advertising and customize
            the Google Display Network ads by visiting the Google Ads Settings
            page.Google also recommends installing the Google Analytics Opt-out
            Browser Add-on for your web browser. Google Analytics Opt-out
            Browser Add-on provides visitors with the ability to prevent their
            data from being collected and used by Google Analytics.For more
            information on the privacy practices of Google, please visit the
            Google Privacy & Terms web page.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Do Not Track Disclosure
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We support Do Not Track (“DNT”). Do Not Track is a preference you
            can set in your web browser to inform websites that you do not want
            to be tracked.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Service Providers
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We may employ third party companies and individuals to facilitate
            our Service, to provide the Service on our behalf, to perform
            Service-related services and/or to assist us in analyzing how our
            Service is used.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            These third parties have access to your Personal Information only to
            perform specific tasks on our behalf and are obligated not to
            disclose or use your information for any other purpose.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Communications
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We may use your Personal Information to contact you with
            newsletters, marketing or promotional materials and other
            information that may be of interest to you. You may opt out of
            receiving any, or all, of these communications from us by following
            the unsubscribe link or instructions provided in any email we send.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Compliance With Laws
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We will disclose your Personal Information where required to do so
            by law or subpoena or if we believe that such action is necessary to
            comply with the law and the reasonable requests of law enforcement
            or to protect the security or integrity of our Service.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Business Transaction
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            If The OutsideIn Consultants is involved in a merger, acquisition or
            asset sale, your Personal Information may be transferred as a
            business asset. In such cases, we will provide notice before your
            Personal Information is transferred and/or becomes subject to a
            different Privacy Policy.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Security
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            The security of your Personal Information is important to us, and we
            strive to implement and maintain reasonable, commercially acceptable
            security procedures and practices appropriate to the nature of the
            information we store, in order to protect it from unauthorized
            access, destruction, use, modification, or disclosure.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            However, please be aware that no method of transmission over the
            internet, or method of electronic storage is 100% secure and we are
            unable to guarantee the absolute security of the Personal
            Information we have collected from you.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            International Transfer
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Your information, including Personal Information, may be transferred
            to — and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            If you are located outside India and choose to provide information
            to us, please note that we transfer the information, including
            Personal Information, to India and process it there.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Links To Other Sites
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party’s site. We strongly advise you to review the
            Privacy Policy of every site you visit.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Children’s Privacy
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Only persons aged 13 or older have permission to access our Service.
            Our Service does not address anyone under the age of 13
            (“Children”).
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We do not knowingly collect personally identifiable information from
            children under 13. If you are a parent or guardian and you learn
            that your Children have provided us with Personal Information,
            please contact us. If we become aware that we have collected
            Personal Information from a children under age 13 without
            verification of parental consent, we take steps to remove that
            information from our servers.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Changes To This Privacy Policy
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            This Privacy Policy is effective as of September 12, 2022 and will
            remain in effect except with respect to any changes in its
            provisions in the future, which will be in effect immediately after
            being posted on this page.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            We reserve the right to update or change our Privacy Policy at any
            time and you should check this Privacy Policy periodically. Your
            continued use of the Service after we post any modifications to the
            Privacy Policy on this page will constitute your acknowledgment of
            the modifications and your consent to abide and be bound by the
            modified Privacy Policy.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            If we make any material changes to this Privacy Policy, we will
            notify you either through the email address you have provided us, or
            by placing a prominent notice on our website.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Contact Us
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            If you have any questions about this Privacy Policy, please contact
            us.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
