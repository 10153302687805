import React, { Suspense } from "react";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { styled } from "@mui/material";
import ThemeProvider from "./theme/index";
import ScrollToTop from "./common/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import Loader from "./common/Loader";
import Routes from "./Routes";

// StyledSnackbarProvider with styles for notistack
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.notistack-MuiContent-success {
    background-color: #975030;
  }
  ,
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <ScrollToTop />
        <StyledSnackbarProvider
          hideIconVariant
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Suspense fallback={<Loader />}>
            <Routes />
          </Suspense>
        </StyledSnackbarProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
