import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Footer from "../common/Footer";
import Navbar from "../layout/Navbar";

const Disclaimer = () => {
  return (
    <>
      <Navbar />
      <Container
        maxWidth="xl"
        sx={{ textAlign: "left", pl: "0 !important", pr: "0 !important" }}
      >
        <Box
          sx={{
            px: { md: 6, sm: 4, xs: 2 },
            py: {
              md: 4,
              xs: 2,
            },
          }}
        >
          <Typography variant="h1" color="primary.main" sx={{ mb: 5 }}>
            Disclaimer
          </Typography>

          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            Design Lense(“us”, “we”, or “our”) operates the onestepcurtains.com
            website (the “Service”).
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            ANY EARNINGS, RESULTS OR INCOME STATEMENTS, OR EARNINGS, RESULTS OR
            INCOME EXAMPLES, ARE ONLY ESTIMATES OF WHAT WE THINK YOU COULD EARN
            OR ACHIEVE. THERE IS NO ASSURANCE YOU’LL DO AS WELL. IF YOU RELY
            UPON OUR FIGURES, YOU MUST ACCEPT THE RISK OF NOT DOING AS WELL.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            WHERE SPECIFIC INCOME FIGURES ARE USED, AND ATTRIBUTED TO AN
            INDIVIDUAL OR BUSINESS, THOSE PERSONS OR BUSINESSES HAVE EARNED THAT
            AMOUNT. THERE IS NO ASSURANCE YOU’LL DO AS WELL. IF YOU RELY UPON
            OUR FIGURES; YOU MUST ACCEPT THE RISK OF NOT DOING AS WELL.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            ANY AND ALL CLAIMS OR REPRESENTATIONS, AS TO INCOME EARNINGS ON THIS
            WEB SITE, ARE NOT TO BE CONSIDERED AS AVERAGE EARNINGS. TESTIMONIALS
            ARE NOT REPRESENTATIVE.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            THERE CAN BE NO ASSURANCE THAT ANY PRIOR SUCCESSES, OR PAST RESULTS,
            AS TO INCOME EARNINGS, CAN BE USED AS AN INDICATION OF YOUR FUTURE
            SUCCESS OR RESULTS.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            MONETARY AND INCOME RESULTS ARE BASED ON MANY FACTORS. WE HAVE NO
            WAY OF KNOWING HOW WELL YOU WILL DO, AS WE DO NOT KNOW YOU, YOUR
            BACKGROUND, YOUR WORK ETHIC, OR YOUR BUSINESS SKILLS OR PRACTICES.
            THEREFORE WE DO NOT GUARANTEE OR IMPLY THAT YOU WILL WIN ANY
            INCENTIVES OR PRIZES THAT MAY BE OFFERED, GET RICH, THAT YOU WILL DO
            AS WELL, OR MAKE ANY MONEY AT ALL. THERE IS NO ASSURANCE YOU’LL DO
            AS WELL. IF YOU RELY UPON OUR FIGURES; YOU MUST ACCEPT THE RISK OF
            NOT DOING AS WELL.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            INTERNET BUSINESSES AND EARNINGS DERIVED THEREFROM, HAVE UNKNOWN
            RISKS INVOLVED, AND ARE NOT SUITABLE FOR EVERYONE. MAKING DECISIONS
            BASED ON ANY INFORMATION PRESENTED IN OUR PRODUCTS, SERVICES, OR WEB
            SITE, SHOULD BE DONE ONLY WITH THE KNOWLEDGE THAT YOU COULD
            EXPERIENCE SIGNIFICANT LOSSES, OR MAKE NO MONEY AT ALL. ONLY RISK
            CAPITAL SHOULD BE USED.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            ALL PRODUCTS AND SERVICES BY OUR COMPANY ARE FOR EDUCATIONAL AND
            INFORMATIONAL PURPOSES ONLY. USE CAUTION AND SEEK THE ADVICE OF
            QUALIFIED PROFESSIONALS. CHECK WITH YOUR ACCOUNTANT, LAWYER OR
            PROFESSIONAL ADVISOR, BEFORE ACTING ON THIS OR ANY INFORMATION.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            USERS OF OUR PRODUCTS, SERVICES AND WEB SITE ARE ADVISED TO DO THEIR
            OWN DUE DILIGENCE WHEN IT COMES TO MAKING BUSINESS DECISIONS AND ALL
            INFORMATION, PRODUCTS, AND SERVICES THAT HAVE BEEN PROVIDED SHOULD
            BE INDEPENDENTLY VERIFIED BY YOUR OWN QUALIFIED PROFESSIONALS. OUR
            INFORMATION, PRODUCTS, AND SERVICES ON THIS WEB SITE SHOULD BE
            CAREFULLY CONSIDERED AND EVALUATED, BEFORE REACHING A BUSINESS
            DECISION, ON WHETHER TO RELY ON THEM. ALL DISCLOSURES AND
            DISCLAIMERS MADE HEREIN OR ON OUR SITE, APPLY EQUALLY TO ANY OFFERS,
            PRIZES, OR INCENTIVES, THAT MAY BE MADE BY OUR COMPANY.
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 400 }}>
            YOU AGREE THAT OUR COMPANY IS NOT RESPONSIBLE FOR THE SUCCESS OR
            FAILURE OF YOUR BUSINESS DECISIONS RELATING TO ANY INFORMATION
            PRESENTED BY OUR COMPANY, OR OUR COMPANY PRODUCTS OR SERVICES.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Disclaimer;
