import PropTypes from "prop-types";
import { useMemo } from "react";
// @mui
import { CssBaseline, responsiveFontSizes } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";
import GlobalStyles from "./globalStyles";
import customShadows from "./customShadows";

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props;

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontWeight: 600,
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
        },
        input: {
          borderRadius: "6px",
          fontSize: "14px",
          fontWeight: 500,
        },
        notchedOutline: {
          borderColor: "#06123714",
          borderWidth: "1.5px",
          borderRadius: "6px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "10px !important",
          fontSize: 18,
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#AE4034",
            },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "6px !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          height: "58px",
          boxShadow: "none",
          fontSize: "16px",
          fontWeight: 700,
          // minWidth: "115px",
          padding: "6px 25px",
          ":hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid #0612370F",
          boxShadow: "0px 0px 10px 0px #0612370F",
          borderRadius: "6px",
          padding: "8px",
          gap: "10px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          ":last-child": {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#FFFFFF",
          color: "#000000",
          borderRadius: "36px",
          backdropFilter: "blur(15px)",
          outline: "none",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 800,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#FFFFFF",
          color: "#AE4034",
          border: "1px solid #0612370F",
          boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.04)",
        },
        arrow: {
          color: "#FFFFFF",
          "::before": {
            border: "1px solid #0612370F",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: "35px",
          height: "35px",
          backgroundColor: "#061237",
        },
      },
    },
    MuiPickersToolbarButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-root": {
            height: "fit-content",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "&.MuiList-root": {
            backgroundColor: "#343434",
            color: "#FFFFFF",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          marginBottom: "20px",
          boxShadow: "0px 5px 16px 0px rgba(1, 27, 54, 0.06)",
          borderRadius: "20px",
          border: " 2px solid rgba(0, 0, 0, 0.07)",
          color: "#000",
          fontSize: 22,
          fontWeight: 600,
          padding: "20px 0",
          transition: "all 0.3s",
          ":first-of-type": {
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          },
          ":last-of-type": {
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          },
          "::before": {
            backgroundColor: "transparent",
            height: 0,
          },
          "&.Mui-expanded": {
            border: "2px solid #AE4034",
            transition: "all 0.3s",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: "#000",
          fontSize: 18,
          fontWeight: 400,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          columnGap: "10px",
          color: "#000",
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
        expandIconWrapper: {
          minWidth: "50px ",
          minHeight: "50px ",
          backgroundColor: "#FFF",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid rgba(0, 0, 0, 0.07)",
          boxShadow: "0px 5px 16px 0px rgba(1, 27, 54, 0.06)",
          transform: "rotate(270deg)",
          transition: "all 0.3s",
          [theme.breakpoints.down("md")]: {
            minWidth: "35px ",
            minHeight: "35px ",
          },
          svg: {
            path: {
              stroke: "#ae4034",
            },
          },
          "&.Mui-expanded": {
            backgroundColor: "#ae4034",
            transform: "rotate(360deg)",
            transition: "all 0.3s",
            svg: {
              path: {
                stroke: "#FFF",
              },
            },
          },
        },
      },
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default ThemeProvider;
