import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import Briefing from "../resources/images/Briefing.svg";
import Concept from "../resources/images/Concept.svg";
import Execution from "../resources/images/Execution.svg";
import { FaInfinity } from "react-icons/fa6";
import Style from "../resources/images/Style.svg";
import Handover from "../resources/images/Handover.svg";

const ProcessData = [
  {
    Icon: Briefing,
    label: "Briefing",
    description:
      "Through open communication and active collaboration, we ensure a tailored and successful design outcome for our clients.",
  },
  {
    Icon: Concept,
    label: "Designing",
    description:
      "We distill client preferences into curated mood boards and innovative drafts, laying the groundwork for meticulous and detailed design development.",
  },
  {
    Icon: <FaInfinity size={100}/>,
    label: "Execution",
    description:
      "Reinventing existing spaces and bringing dreams to life, we ensure extensive hands-on experience in constructing personalized environments.",
  },
  {
    Icon: Style,
    label: "Styling",
    description:
      "From colors and decor to furniture selection, we attend to every minute detail required to transform a house into a home that reflects our clients' style and personality. ",
  },
  {
    Icon: Handover,
    label: "Handover",
    description:
      "The eagerly awaited moment unfolds as we personally present our clients with the key to their new space, meticulously prepared for effortless move-in.",
  },
];

const OurProcess = () => {
  // useEffect(() => {
  //   AOS.init();
  // }, []);

  return (
    <Box id="ourProcess">
      <Container
        maxWidth="xl"
        sx={{ pl: "0 !important", pr: "0 !important", my: 8 }}
      >
        <Box
          sx={{
            px: {
              lg: 12,
              md: 8,
              sm: 4,
              xs: 2,
            },
            py: {
              md: 8,
              xs: 6,
            },
          }}
        >
          <Typography variant="h1" textAlign="center" fontWeight="800">
            Our Process
          </Typography>
          <Grid container spacing={5} sx={{ mt: 2 }}>
            {ProcessData.map((step, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2.4}
                key={index}
                height="100%"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{
                    pr: {
                      xs: 0,
                      sm: index === 4 || index === 1 || index === 3 ? 0 : 3,
                      md: index === 4 || index === 2 ? 0 : 3,
                      lg: index === ProcessData.length - 1 ? 0 : 3,
                    },
                  }}
                >
                  {step.label === "Execution" ? (
                    step?.Icon
                  ) : (
                    <img
                      src={step.Icon}
                      alt={step.label}
                      height="100px"
                      width="100px"
                      loading="lazy"
                    />
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  textAlign="center"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{
                    height: "250px",
                    borderRight: {
                      xs: "none",
                      sm:
                        index === 4 || index === 1 || index === 3
                          ? "none"
                          : "3px solid #65656520",
                      md:
                        index === 4 || index === 2
                          ? "none"
                          : "3px solid #65656520",
                      lg:
                        index === ProcessData.length - 1
                          ? "none"
                          : "3px solid #65656520",
                    },
                    pr: {
                      xs: 0,
                      sm: index === 4 || index === 1 || index === 3 ? 0 : 4,
                      md: index === 4 || index === 2 ? 0 : 4,
                      lg: index === ProcessData.length - 1 ? 0 : 4,
                    },
                  }}
                >
                  <Typography
                    variant="h4"
                    mt={3}
                    fontWeight="400"
                    color="#AE4034"
                  >
                    {step.label}
                  </Typography>
                  <Typography variant="body1" mt={3}>
                    {step.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default OurProcess;
