import React from "react";
import { Container, Box, Grid, Typography, Divider } from "@mui/material";
import Art from "../resources/images/Art.jpg";
import M1 from "../resources/images/Member1.jpg";
import M2 from "../resources/images/Member2.jpg";

const AboutUs = () => {
  return (
    <Container
      maxWidth="xl"
      id="aboutUs"
      sx={{ pl: "0 !important", pr: "0 !important", my: 8 }}
    >
      <Box
        sx={{
          px: {
            lg: 12,
            md: 8,
            sm: 4,
            xs: 2,
          },
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} md={8}>
            <Box>
              <Typography variant="h1" fontWeight="800">
                About Us
              </Typography>
              <Typography variant="body1" mt={2}>
                Boketto is a boutique design firm committed to delivering
                transformed spaces with end-to-end services in architecture and
                interior design.
              </Typography>
              <Typography variant="body1">
                We as a brand, intend to create beautiful spaces through the
                imaginative lens of wandering minds that tastefully build your
                dreams into a reality. We at Boketto, build design stories
                driven by emotions, inspirations and dreams. Every spatial
                condition makes us consider the endless possibilities the
                horizon contains and these intimate spaces binds us to them
                emotionally.
              </Typography>
            </Box>
            <Grid container spacing={6} mt={2} alignItems={"self-start"}>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box display="flex" justifyContent="center">
                  <img
                    src={M1}
                    alt="member1"
                    height="300px"
                    width="300px"
                    loading="lazy"
                  />
                </Box>
                <Box textAlign="center" mb={1}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Dancing Script, cursive",
                      fontWeight: "700",
                    }}
                  >
                    Sakshi Gupta
                  </Typography>
                  <Typography variant="caption">
                    Architect & Interior Designer (Milan)
                  </Typography>
                </Box>
                <Divider sx={{ backgroundColor: "#AE4034" }} />
                <Typography
                  variant="body1"
                  textAlign="justify"
                  color="#AE4034"
                  my={1}
                  sx={{
                    height: {
                      sm: "280px",
                      md: "310px",
                      lg: "220px",
                    },
                  }}
                >
                  Architecture was always her calling, but her passion for
                  Interior design was realised in Milan, Italy, where she
                  attended her masters course for interior and living space
                  Planning at DOMUS Academy, Milan, and got her hands on
                  experience with highly interactive design firms at home and
                  abroad. Her knack for minute details makes her a master and a
                  firm believer in "God lies in details."
                </Typography>
                <Divider sx={{ backgroundColor: "#AE4034" }} />
                <Typography
                  variant="caption"
                  textAlign="center"
                  color="#AE4034"
                  mt={1}
                >
                  She is a desperate seeker of adventure and <br />
                  philosophical Knowlege!
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box display="flex" justifyContent="center">
                  <img
                    src={M2}
                    alt="member1"
                    height="300px"
                    width="300px"
                    loading="lazy"
                  />
                </Box>
                <Box textAlign="center" mb={1}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Dancing Script, cursive",
                      fontWeight: "700",
                    }}
                  >
                    Sheifali Aggarwal
                  </Typography>
                  <Typography variant="caption">Architect</Typography>
                </Box>
                <Divider sx={{ backgroundColor: "#AE4034" }} />
                <Typography
                  variant="body1"
                  textAlign="justify"
                  color="#AE4034"
                  my={1}
                  sx={{
                    height: {
                      sm: "280px",
                      md: "310px",
                      lg: "220px",
                    },
                  }}
                >
                  After completing her bachelor's degree in architecture,
                  Sheifali worked with multiple organisations to find her
                  calling. Her time at these firms helped her grow
                  professionally and made her believe in teamwork. She finds it
                  constructive and simulating to confront new working
                  experiences and share her passion for creativity and design.
                </Typography>
                <Divider sx={{ backgroundColor: "#AE4034" }} />
                <Typography
                  variant="caption"
                  textAlign="center"
                  color="#AE4034"
                  mt={1}
                >
                  She is an ardent gourmet traveller and <br />
                  beholds her family time!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center">
              <img src={Art} alt="art" loading="lazy" />
            </Box>
            <Typography variant="h3" textAlign="center" fontWeight="900">
              “Curating Inspired Spaces:
              <br />A Reflection of you”
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AboutUs;
