import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import I1 from "../resources/images/I1.jpg";
import I2 from "../resources/images/I2.jpg";
import I3 from "../resources/images/I3.jpeg";
import I4 from "../resources/images/I4.jpg";
import I5 from "../resources/images/I5.jpeg";
import Img1 from "../resources/images/Img1.jpg";
import Img2 from "../resources/images/Img2.jpg";
import Img3 from "../resources/images/Img3.jpeg";
import Img4 from "../resources/images/Img4.jpg";
import Img5 from "../resources/images/Img5.jpg";
import { useLocation } from "react-router-dom";

const imgs = [I1, I2, I3, I4, I5];
const images = [Img1, Img2, Img3, Img4, Img5];

const InteriorBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();
  const isSpecialPage = location.pathname === "/app-interior";

  useEffect(() => {
    const activeInterval = setInterval(nextImg, 4000);
    const currentInterval = setInterval(nextImage, 4000);

    return () => {
      clearInterval(activeInterval);
      clearInterval(currentInterval);
    };
  }, []);

  // useEffect(() => {
  //   AOS.init();
  // }, []);

  const nextImg = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ pl: "0 !important", pr: "0 !important" }}
      // data-aos="fade-up"
    >
      <Grid container>
        <Grid item xs={6} style={{ overflow: "hidden" }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: { md: "680px", xs: "400px" },
              transform: "translateZ(0)",
            }}
          >
            {imgs.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index}`}
                style={{
                  position: "absolute",
                  top: 0,
                  right: index === currentIndex ? 0 : "100%",
                  transform: `scale(${index === currentIndex ? 1.2 : 1})`,
                  transition: "transform 4s cubic-bezier(.25,.72,.63,.95)",
                  transitionDelay: 500,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                loading="lazy"
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={6} style={{ overflow: "hidden" }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: { md: "680px", xs: "400px" },
              transform: "translateZ(0)",
            }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index}`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: index === activeIndex ? 0 : "100%",
                  transform: `scale(${index === activeIndex ? 1.2 : 1})`,
                  transition: "transform 4s cubic-bezier(.25,.72,.63,.95)",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                loading="lazy"
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InteriorBanner;
